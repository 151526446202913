<template>
  <core-section id="lets-talk">
    <core-heading>Let's talk.</core-heading>

    <v-col
      cols="12"
      md="5"
      class="mb-4 pr-md-5"
    >
      <!-- <core-subheading class="text-uppercase">
        What can I do for you?
      </core-subheading> -->

      <core-text class="mb-5">
        {{ schema.basics.forYou }}
      </core-text>

      <core-subheading class="text-uppercase mb-3">
        Send me a Message
      </core-subheading>
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-form
          ref="contactForm"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Name"
            rules="required|max:100"
          >
            <v-text-field
              v-model="contactDetails['name']"
              :error-messages="errors"
              filled
              label="Name"
              required
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Email"
            rules="required|email|max:100"
          >
            <v-text-field
              v-model="contactDetails['email']"
              :error-messages="errors"
              filled
              label="Email"
              required
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="required|max:100"
            name="Subject"
          >
            <v-text-field
              v-model="contactDetails['subject']"
              :error-messages="errors"
              filled
              label="Subject"
              required
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            rules="required|max:500"
            name="Message"
          >
            <v-textarea
              v-model="contactDetails['message']"
              :error-messages="errors"
              :counter="500"
              filled
              label="Message"
              required
            />
          </validation-provider>
          <div class="pb-4 mb-2">
            <vue-recaptcha
              ref="recaptcha"
              :sitekey="recaptchaSiteKey"
              :load-recaptcha-script="true"
              @verify="onVerify"
            />
          </div>
          <v-btn
            class="ma-0"
            color="primary"
            :disabled="invalid"
            @click="submit"
          >
            Contact Me
          </v-btn>
        </v-form>
      </validation-observer>
    </v-col>

    <v-col
      cols="12"
      md="5"
      class="text-left"
    >
      <core-subheading class="text-uppercase">
        Social Contacts
      </core-subheading>

      <social-contacts />

      <v-list
        class="transparent"
        three-line
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon
              class="mr-5"
              color="primary"
              size="48"
            >
              mdi-map-marker
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              Address
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ schema.basics.location.address }}<br>
              {{ schema.basics.location.city }}, {{ schema.basics.location.postalCode }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon
              size="48"
              color="primary"
              class="mr-5"
            >
              mdi-email
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              Email
            </v-list-item-title>
            <v-list-item-subtitle v-text="schema.basics.email" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon
              size="48"
              color="primary"
              class="mr-5"
            >
              mdi-phone
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              Phone
            </v-list-item-title>
            <v-list-item-subtitle v-text="schema.basics.phone" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>

    <v-img
      :src="require('@/assets/josh-alt.png')"
      contain
      max-width="30vw"
      style="bottom: 0px; right: 0; position: absolute;"
      width="100%"
    />
    <v-snackbar
      v-model="snackbar"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </core-section>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  import { db } from '../firebase/firebase'

  import VueRecaptcha from 'vue-recaptcha'
  import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

  setInteractionMode('eager')

  extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
  })

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
  })

  extend('email', {
    ...email,
    message: 'Email must be valid',
  })

  export default {
    name: 'LetsTalk',

    components: {
      SocialContacts: () => import('@/components/SocialContacts'),
      VueRecaptcha,
      ValidationProvider,
      ValidationObserver,
    },

    data () {
      return {
        snackbar: false,
        text: '',
        recaptchaSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA,
        contactDetails: { name: '', email: '', subject: '', message: '', robot: true },
      }
    },

    computed: {
      ...mapState('app', ['schema']),
      messagesCollection () {
        return db.collection('messages')
      },
    },

    methods: {
      submit: function (e) {
        e.preventDefault()
        this.$refs.observer.validate()
        if (this.contactDetails.robot) {
          this.snackbarMessage('Are you a robot?')
        } else {
          this.messagesCollection.add(
            {
              name: this.contactDetails.name,
              email: this.contactDetails.email,
              subject: this.contactDetails.subject,
              message: this.contactDetails.message,
              time: new Date(),
            },
          ).then(() =>
            this.clear(),
          )
          this.snackbarMessage('Message sent.')
        }
      },
      snackbarMessage (message) {
        this.text = message
        this.snackbar = true
      },
      clear () {
        this.contactDetails.name = ''
        this.contactDetails.email = ''
        this.contactDetails.subject = ''
        this.contactDetails.message = ''
        this.contactDetails.robot = true
        this.$refs.recaptcha.reset()
        this.$refs.observer.reset()
      },
      onVerify (response) {
        if (response) this.contactDetails.robot = false
      },
    },
  }
</script>
